// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-search-results-js": () => import("./../../../src/pages/search-results.js" /* webpackChunkName: "component---src-pages-search-results-js" */),
  "component---src-templates-article-page-js": () => import("./../../../src/templates/articlePage.js" /* webpackChunkName: "component---src-templates-article-page-js" */),
  "component---src-templates-article-right-rail-page-js": () => import("./../../../src/templates/articleRightRailPage.js" /* webpackChunkName: "component---src-templates-article-right-rail-page-js" */),
  "component---src-templates-topic-hub-page-js": () => import("./../../../src/templates/topicHubPage.js" /* webpackChunkName: "component---src-templates-topic-hub-page-js" */)
}

