import React from "react"
import axios from "axios"
import { Provider } from "react-redux"
import { setConfig } from "./src/state/actions"
import { store } from "./src/state/store"

import "./src/styles/tds.scss"
import "trv-ebus-tcom-reactcomponents/dist/index.css"

const shouldUpdateScroll = () => [0, 0]

const onClientEntry = async () => {
  try {
    let response = await axios.get("/config/settings.json")
    store.dispatch(setConfig(response.data))
  } catch (ex) {
    console.log(ex)
  }
}

const wrapRootElement = ({ element }) => {
  return <Provider store={store}>{element}</Provider>
}

const onRouteUpdate = ({ location }) => {
  window.utag &&
    window.utag.view({
      dl: location.href,
    })
}

export { shouldUpdateScroll, onClientEntry, wrapRootElement, onRouteUpdate }
